.categories {
  background-color: white;
  border-radius: .8rem .8rem 0 0;
}

.categories-title {
  gap: .2em;
  align-items: center;
  margin-bottom: 2em;
}

.categories-cards {
  display: grid;
  grid-gap: max(2rem, 10%);
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
}


@media screen and (min-width: 600px) {
  .categories {
    width: 50%;
  }
}