.stats-top {
    justify-content: space-between;
    align-items: center;
}

.center {
    align-items: center;
    justify-content: space-between;
}

.stats-daily-expense {
    position: relative;
}

.stats-daily-expense>span {
    position: absolute;
    right: 45%;
    top: 45%;
}

.stats-category p {
    margin: 0;
    margin-left: .5em;
}

.recharts-wrapper {
    max-width: 100%;
    display: flex;
    margin-inline: auto;
}

.category-circle {
    width: .8rem;
    height: .8rem;
    border-radius: 50%;
}

.stats-fails {
    text-align: center;
}

.stats-fails img {
    width: 50%;
    max-width: 200px;
}


@media screen and (min-width: 600px) {
    .stats {
        max-width: 55ch;
        margin-inline: auto;
    }
}