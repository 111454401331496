.categ-spent {
    gap: 5%;
    grid-template-columns: 70% 5%;
}

.categ-spent-card p {
    margin: 0;
}

.spent_row1 {
    justify-content: space-between;
    align-items: center;
}

.spent_row2 {
    gap: 1em;
    color: var(--clr-secondary-200);
}

.spent-edit-tools {
    padding-block: 1rem;
    flex-direction: column;
    justify-content: space-between;
}