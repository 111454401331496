.welcome {
  flex-direction: column;
  gap: clamp(3rem, 4vh + 2rem, 6rem);
}

.home .welcome,
.welcome h1,
.welcome h5,
.welcome button,
.welcome span {
  color: hsl(var(--clr-primary-100));
}

.name {
  letter-spacing: 0.02em;
}

.welcome-buttons {
  justify-content: space-evenly;
}

.welcome-btn-container {
  gap: .5em;
  justify-items: center;
}

.welcome-buttons span {
  width: calc(3.2rem + 3vw);
  height: calc(3.2rem + 3vw);
  padding: 0;
}

@media screen and (min-width: 600px) {
  .welcome {
    width: 50%;
    background-image: url("/public/home_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
  }
}