/*==================== VARIABLES CSS ====================*/
:root {
  /*========== Colors ==========*/
  --clr-text: 0, 1%, 25%;
  --clr-primary-900: 0, 1%, 15%;
  --clr-secondary-200: hsl(0, 0%, 51%);
  --clr-secondary-100: 0, 8%, 85%, 20%;
  --clr-primary-200: hsl(270, 5%, 85%);
  --clr-primary-100: 29, 100%, 100%;
  --clr-card-container: hsl(0, 0%, 96%);
  --clr-modal-label: hsl(0, 1.3%, 29.4%);
  --clr-modal-input: hsl(0, 1%, 57%);

  /*========== Font and typography ==========*/
  --body-font: 'Raleway', sans-serif;
  --ff-remark: 'Inter', sans-serif;
  --ff-prices: 'Montserrat', sans-serif;

  --big-fs: calc(1.875rem + 1vw);
  --h1-fs: calc(1.5rem + .4vw);
  --h2-fs: calc(1.375rem + .3vw);
  --h3-fs: calc(1.125rem + .2vw);
  --normal-fs: calc(1rem + .2vw);
  --small-fs: calc(.875rem + .2vw);
  --smaller-fs: calc(.75rem + .2vw);
  --icon-fs: calc(1.125rem + 1.5vw);

  /*========== Font weight ==========*/
  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semi-bold: 600;
  --fw-bold: 700;

  /*========== Margenes==========*/
  --m-0-5: .5em;
  --m-1: 1rem;
  --m-1-5: 1.5rem;
  --m-2: 2rem;
  --m-2-5: 2.5rem;
}

/* Font size for large devices */
@media screen and (min-width: 62.5rem) {
  :root {
    --big-fs: 2.375rem;
    --h1-fs: 1.75rem;
    --h2-fs: 1.5rem;
    --h3-fs: 1.25rem;
    --normal-fs: 1.125rem;
    --small-fs: 1rem;
    --smaller-fs: .875rem;
    --icon-fs: 1.875rem;
  }
}