.category_view-info {
  position: relative;
}

.category-top {
  justify-content: space-between;
}

.category_view-title {
  margin-top: 1rem !important;
}

.category_view-amount {
  justify-content: end;
  gap: .5em;
}

.rounded-pill {
  height: .5em !important;
}

.categ-expenses {
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
}

@media screen and (min-width: 600px) {
  .category_view {
    max-width: 55ch;
    margin-inline: auto;
  }
}