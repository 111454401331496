.home .category-card {
  grid-template-columns: 70% 20%;
  gap: 10%;
}

.category-card .progress {
  flex-direction: column-reverse;
  align-items: center;
  height: 100%;
  width: 1rem;
}

.category-card .progress-bar-label {
  font-size: .6rem;
  rotate: -90deg;
  transform: translateX(30%);
  color: hsl(var(--clr-primary-100));
  position: absolute;
}

.category-card .progress-bar {
  background-color: hsl(var(--clr-primary-900));
}

.categ-percent-bar {
  justify-self: center;
}

.card-emoji {
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  border-radius: .5em;
}

.card-name {
  margin-bottom: .5em;
  color: var(--clr-secondary-200)
}

.emoji {
  filter: sepia(.4) grayscale(50%);
}