* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

.body {
  font-family: var(--body-font);
  font-size: var(--normal-fs);
  font-weight: var(--fw-regular);
  background-color: hsl(var(--clr-primary-100));
  color: hsl(var(--clr-text));
}

#root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.home {
  height: 100%;
}

.body h1,
.body h2,
.body h3,
.body h4,
.body h5 {
  color: hsl(var(--clr-primary-900));
  font-weight: var(--fw-semi-bold);
  margin: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

i:hover {
  cursor: pointer;
}

/*==================== TYPOGRAPHY ====================*/
.body .ff-price {
  font-family: var(--ff-prices);
}

.body .ff-remark {
  font-family: var(--ff-remark);
}

.body .fs-big {
  font-size: var(--big-fs);
}

.body .fs-700 {
  font-size: var(--h1-fs);
}

.body .fs-600 {
  font-size: var(--h2-fs);
}

.body .fs-500 {
  font-size: var(--h3-fs);
}

.body .fs-small {
  font-size: var(--small-fs);
}

.body .fs-smallest {
  font-size: var(--smaller-fs);
}

.body .fs-icon {
  font-size: var(--icon-fs);
}

.body .fw-600 {
  font-weight: var(--fw-semi-bold);
}

.body .fw-500 {
  font-weight: var(--fw-medium);
}

.body .fw-300 {
  font-weight: var(--fw-light);
}

/*==================== SECTIONS ====================*/
.section {
  padding: 2rem 0 4rem;
}

.section__remark {
  background-color: hsl(var(--clr-light-300));
}

.section__title {
  font-size: var(--h1-fs);
}

.section__subtitle {
  display: block;
  font-size: var(--small-fs);
  margin-bottom: var(--mb-3);
}

.section__title,
.section__subtitle {
  text-align: center;
}

/*==================== ELEMENTS ====================*/
.body .btn-custom {
  border: 0;
  border-radius: .8em;
  padding: .4em.6em;
}

.body .btn-traslucid {
  background-color: hsla(var(--clr-secondary-100));
}

.body .btn-transparent {
  border: 1px solid hsl(var(--clr-primary-900));
  background-color: transparent;
}

.body .card-custom {
  border: 1px solid var(--clr-primary-200);
  border-radius: 1.5rem;
  padding: 1rem;
  background-color: hsl(var(--clr-primary-100));
}

.link-reset {
  color: inherit;
  text-decoration: none;
}

/*==================== BACKGROUND ====================*/
.body .home {
  background-image: url("/public/home_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

/*==================== LAYOUT ====================*/
.body .container-limits {
  padding-block: max(2rem, 10vh);
  padding-inline: max(1rem, 5%);
}

.body .flex {
  display: flex;
}

.body .grid {
  display: grid;
}

.custom-stack-0-5>*+* {
  margin-top: var(--m-0-5);
}

.custom-stack-1>*+* {
  margin-top: var(--m-1);
}

.custom-stack-1-5>*+* {
  margin-top: var(--m-1-5);
}

.custom-stack-2>*+* {
  margin-top: var(--m-2);
}

.custom-stack-2-5>*+* {
  margin-top: var(--m-2-5);
}

.body .modal-custom {
  color: var(--clr-modal-label);
}

.modal-custom input, .modal-custom select {
  color: var(--clr-modal-input);
}

@media screen and (min-width: 600px) {
  .body .home {
    display: flex;
    background: none;
  }
}